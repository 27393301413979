// import logo from './logo.svg';
import { Divider, Flex, Layout, Space, Typography, } from 'antd';

import {
  BrowserRouter, Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import './App.css';
import styles from './App.module.css';
import About from './view/about';
import Health from './view/health';
import Music from './view/music';
import Painting from './view/painting';
import Culture from './view/culture';
import Classic from './view/classic';
import bg from './asset/bg.jpg';

export default () => {
  const basename = document
    ?.querySelector('meta[name="base"]')
    ?.getAttribute("content") ?? process.env.REACT_APP_BASE_PATH;
  return <BrowserRouter basename={basename}>
    <Flex justify='center' style={{minHeight: '100vh', position: 'relative'}}>
      <Flex vertical style={{
          position: 'absolute',
          left: 0, top: 0, right: 0, bottom: 0, zIndex: 0, opacity: 0.4,
          overflow: 'clip',
      }} align='stretch'>
        <img src={bg} />
      </Flex>
      <Layout style={{ maxWidth: 1024, zIndex: 200 , background: 'rgba(255,255,255, 0.4)', padding: '0 40px'}}>
        <Layout.Header style={{ background: 'transparent', color: 'white', padding: '10px 40px', height: 'auto' }}>
          <Space size={0} direction='vertical'>
            <Typography.Title level={2}>众焱文化</Typography.Title>
            <Flex align='center'>
              <Typography.Link href='https://www.gov.cn/gongbao/content/2022/content_5678093.htm' target='_blank'>宗教政策</Typography.Link>
              <Divider type='vertical' />
              <Typography.Link href='/culture'>道教文化</Typography.Link>
            </Flex>
          </Space>
        </Layout.Header>
        <Layout.Content style={{ padding: '20px 40px', position: 'relative' }}>
          <Routes>
            <Route path="/" element={<Culture />} />
            <Route path="/about" element={<About />} />
            <Route path="/health" element={<Health />} />
            <Route path="/music" element={<Music />} />
            <Route path="/painting" element={<Painting />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/classic" element={<Classic />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout.Content>
        <Layout.Footer className={styles.footer}>
          <div> 工业和信息化部备案管理系统网站 闽ICP备2024080376号-1 </div>
          <div> 主办单位：福州闽达人力资源有限公司 </div>
        </Layout.Footer>
      </Layout>
    </Flex>
  </BrowserRouter>
};
